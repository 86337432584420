exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-features-js": () => import("./../../../src/pages/all-features.js" /* webpackChunkName: "component---src-pages-all-features-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-call-center-tools-js": () => import("./../../../src/pages/call-center-tools.js" /* webpackChunkName: "component---src-pages-call-center-tools-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fax-email-js": () => import("./../../../src/pages/fax-email.js" /* webpackChunkName: "component---src-pages-fax-email-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-microsoft-teams-js": () => import("./../../../src/pages/microsoft-teams.js" /* webpackChunkName: "component---src-pages-microsoft-teams-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-voice-ucaas-js": () => import("./../../../src/pages/voice-ucaas.js" /* webpackChunkName: "component---src-pages-voice-ucaas-js" */),
  "component---src-pages-web-video-colab-js": () => import("./../../../src/pages/web-video-colab.js" /* webpackChunkName: "component---src-pages-web-video-colab-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

